import styled from 'styled-components';

export const Container = styled.div`
  margin-left: 25%;
  padding: 64px;

  .buttons {
    display: flex;
    align-items: center;
    justify-content: end;

    margin-top: 16px;
    margin-right: -16px;

    & > button {
      margin-right: 16px;
    }
  }

  @media screen and (max-width: 767px) {
    padding: 24px;

    margin-left: 0;

    .buttons {
      justify-content: space-between;

      button {
        width: 100%;
      }
    }
  }
`;

export const Title = styled.div`
  padding: 32px;

  border-radius: 25px;

  background-color: ${({ theme }) => theme.colors.main.light};

  h1 {
    color: ${({ theme }) => theme.colors.gray.medium};
    font-size: 36px;
  }

  p {
    color: ${({ theme }) => theme.colors.gray.medium};
    font-size: 24px;
  }

  @media screen and (max-width: 767px) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    padding: 16px;

    text-align: center;

    border-radius: 8px;

    h1 {
      margin-bottom: 8px;

      font-size: 16px;

      color: ${({ theme }) => theme.colors.main.alertBold};
    }

    p {
      font-size: 14px;
    }
  }
`;

export const Body = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 16px;

  height: 25rem;

  border-radius: 25px;

  background-color: ${({ theme }) => theme.colors.main.light};

  @media screen and (max-width: 767px) {
    border-radius: 8px;

    font-size: 14px;
  }
`;
