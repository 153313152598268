import {
  Container,
  Title,
  Body,
} from './styles';

import Header from '../../components/Header';
import Button from '../../components/Button';

export default function Functions() {
  return (
    <>
      <Header />

      <Container>
        <div className="main">
          <Title>
            <h1>Escolha suas funcionalidades</h1>

            <p>Selecione as funções que deseja ter em seu sistema.</p>
          </Title>

          <Body>
            <h1>EM BREVE</h1>
          </Body>

          <section className="buttons">
            <Button
              danger
              disabled
            >
              Cancelar
            </Button>

            <Button
              disabled
            >
              Confirmar
            </Button>
          </section>
        </div>
      </Container>
    </>
  );
};
