import axios from 'axios';

export function setupApiClient() {
  const api = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  api.interceptors.request.use(async (config) => {
    const storageToken = localStorage.getItem('@Auth:token');
    const token = storageToken?.replace(/"/g, '');

    if (token) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  });

  api.interceptors.response.use((response) => response, (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem('@Auth:user');
      localStorage.removeItem('@Auth:email');
      localStorage.removeItem('@Auth:token');

      window.location.href = '/';
    };

    return Promise.reject(error);
  });

  return api;
}
